<template>
  <v-col
    cols="12"
    v-bind="columnProperties"
  >
    <v-card
      v-bind="cartProperties"
      outlined
      :raised="isRowSelected"
    >
      <!-- 主要資料 -->
      <cardMain
        :list-key="listKey"
        :row="row"
      ></cardMain>

      <!-- 一般內容 -->
      <v-card-text v-if="content">
        <html-content v-if="isHtmlContent" :value="content"></html-content>
        <div v-if="!isHtmlContent">{{content}}</div>
      </v-card-text>

      <!-- 自訂body元件 -->
      <v-card-text v-if="bodySlot">
        <component
          :list-key="listKey"
          :row="row"
          :is="bodySlot"
        ></component>
      </v-card-text>

      <v-card-actions v-if="listDataAction">
        <listDataAction
          :row="row"
          :list-key="listKey"
        ></listDataAction>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import listRow from '@/components/list/mixins/listRow'
export default {
  mixins: [listRow],
  methods: {
    getGridConfig(device) {
      if(!this.gridConfig) return null
      return window.eagleLodash.get(this.gridConfig, [device])
    },
  },
  computed: {
    isHtmlContent() {
      if(!this.cardConfig) return false
      return this.cardConfig.isHtmlContent === true
    },
    bodySlot() {
      if(!this.cardConfig) return false
      return this.cardConfig.bodySlot
    },
    content() {
      if(!this.cardConfig) return false
      if(typeof this.cardConfig.content != 'function') return null
      return this.cardConfig.content(this.row)
    },
    cardConfig() {
      return this.$store.getters[`list/${this.listKey}/cardConfig`]
    },
    listDataAction() {
      return this.$store.getters[`list/${this.listKey}/dataAction`]
    },
    gridConfig() {
      return window.eagleLodash.get(this.cardConfig, ['grid'])
    },
    columnProperties() {
      return {
        xl: this.getGridConfig('xl') || 3,
        lg: this.getGridConfig('lg') || 4,
        md: this.getGridConfig('md') || 4,
        sm: this.getGridConfig('sm') || 6,
        xs: this.getGridConfig('xs') || 12,
      }
    },
    cartProperties() {
      return {
        height: window.eagleLodash.get(this.cardConfig, ['height']) || null,
        maxHeight: window.eagleLodash.get(this.cardConfig, ['maxHeight']) || null,
      }
    },
  },
  components: {
    cardMain: () => import('@/components/list/cardMode/cardMain'),
    listDataAction: () => import('@/components/list/listDataAction.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
